<template>
  <div
    v-if="paymentPlans.length && paymentPlans[0].length"
    class="flex flex-col-reverse gap-6 md:flex-row md:gap-10 md:p-8"
  >
    <div class="w-full rounded-3xl bg-white px-3 py-4 md:w-4/12 md:p-0">
      <div class="mb-3 md:mb-4">
        <h4 class="text-subhead-3 md:text-h4 mb-1 md:mb-2">Payment plan</h4>
        <div class="flex items-center justify-between">
          <p class="text-caption-2 md:text-body pr-20 text-black-70">
            {{ isMobile ? 'Variations of plans for different units.' : item.payment_plans[activeSlide].title.en }}
          </p>
        </div>
      </div>

      <div class="relative">
        <client-only>
          <Swiper navigation :modules="[SwiperNavigation]" class="!overflow-y-visible" @slide-change="onSlideChange">
            <SwiperSlide v-for="(plan, index) in paymentPlans" :key="index" class="!w-full cursor-pointer select-none">
              <div class="text-subhead-4 mx-auto mb-2 text-center md:hidden">
                {{ item.payment_plans[activeSlide].title.en }}
              </div>
              <div class="rounded-2xl border border-dm-50 p-3 md:p-6">
                <div v-for="(it, i) in plan" :key="i" class="relative flex min-h-14 pb-2.5">
                  <p class="absolute bottom-2 left-2 top-6 w-[1.5px] bg-black-30"></p>
                  <p
                    :class="{
                      'bg-success-100': it.key === 'on_handover_percent' || it.key === 'post_handover_percent',
                    }"
                    class="text-caption-3 flex size-[18px] items-center justify-center rounded-full bg-primary-100 p-0.5 text-white"
                  >
                    {{ i + 1 }}
                  </p>
                  <div class="ml-4">
                    <p class="text-caption md:text-subhead-2 mb-1">{{ it.name }}</p>
                    <p v-if="it.repeatCount" class="text-caption-4 md:text-subhead-4 mb-1">
                      {{ it.repeatCount }} installments
                    </p>
                    <p v-if="it.date" class="text-caption-2 md:text-body-2 text-black-40">
                      {{ it.date }}
                    </p>
                  </div>
                  <p class="text-caption md:text-subhead-4 ml-auto text-black-70">{{ it.percent }}</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </client-only>
      </div>
    </div>
    <div class="w-full rounded-3xl bg-white px-3 py-4 md:w-8/12 md:p-0">
      <div class="mb-3 flex items-end justify-between md:mb-4">
        <div>
          <h4 class="text-subhead-3 md:text-h4 mb-1 md:mb-2">Prices</h4>
          <p class="text-caption-2 md:text-body text-black-70">
            Last update {{ format(new Date(item.updated_at), 'dd MMMM yyyy hh:mm') }}
          </p>
        </div>
        <!-- <div class="flex rounded-xl bg-black-03 p-0.5">
          <button
            v-for="(item, index) in currencyOptions"
            :key="index"
            class="text-caption flex size-9 items-center justify-center rounded-lg p-1 text-black-60 transition"
            :class="{
              'bg-dm-80 text-white shadow': currency === item.value,
            }"
            @click="currency = item.value"
          >
            {{ item.label }}
          </button>
        </div> -->
      </div>
      <div class="flex flex-col gap-3 md:flex-col-reverse md:gap-6">
        <PropertyTabsPriceUnits :property-detailed="item" @open-units="$emit('open-units')" />
        <div
          v-if="showCommission"
          class="relative flex items-center justify-between rounded-2xl bg-primary-60 p-3 md:justify-normal md:px-8 md:py-6"
        >
          <h2
            class="text-caption md:text-h2 mr-2 mt-auto inline-flex rounded-lg bg-additional-1-100 px-3 py-1 text-black-90 md:mb-2"
          >
            No fees, just keys
          </h2>
          <img :src="CommissionArrow" alt="commission-arrow" class="hidden md:block" />
          <img :src="MobileCommissionArrow" alt="commission-arrow" class="absolute left-1/3 top-3 md:hidden" />
          <div
            class="relative ml-4 flex h-[76px] w-[87px] flex-col items-center justify-center rounded-2xl bg-white p-3 md:h-[98px] md:w-[108px]"
          >
            <p class="text-caption md:text-subhead-2 mb-3 text-black-90">Agency Fee</p>
            <p class="text-4xl font-medium md:text-5xl">0</p>
            <span class="text-subhead-1 absolute bottom-2 right-4 text-black-40 md:bottom-7 md:right-5">%</span>
            <img
              :src="StarEmoji"
              alt="star-emoji"
              class="absolute -right-4 -top-4 size-8 md:-right-16 md:-top-6 md:size-20"
            />
          </div>
        </div>
        <div v-else class="flex gap-6 rounded-2xl bg-dm-80 p-8">
          <div class="flex items-center gap-1.5 rounded-lg bg-white px-3 py-7">
            <p
              class="size-4 rounded-full"
              :style="{ backgroundColor: nameToColorPropertyMap[item.sales_status.en] }"
            ></p>
            <p class="text-subhead-2 text-black-90">{{ item.sales_status.en }}</p>
          </div>
          <div class="flex-1 rounded-lg bg-white px-4 py-3 pr-20">
            <p class="text-subhead-2 mb-2 text-black-90">
              {{ isNotFinished ? 'Project is expected to be finished in' : 'Project was finished in' }}
              <span class="text-primary-100">{{ format(new Date(item.planned_completion_at), 'QQQ yyyy') }}</span>
            </p>
            <div class="relative h-2 flex-1 overflow-hidden rounded-md bg-black-05">
              <p class="absolute h-full rounded-md bg-primary-100" :style="{ width: progressPercentage + '%' }"></p>
            </div>
            <p class="text-caption-2 text-black-90">{{ progressPercentage }}% completed</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format, isFuture } from 'date-fns'
import type { PropertyTypes } from '@/types'
import StarEmoji from '@/assets/images/star-emoji.svg?url'
import CommissionArrow from '@/assets/images/commission-arrow.svg?url'
import MobileCommissionArrow from '@/assets/images/mobile-commission-arrow.svg?url'

defineEmits(['open-units'])

type Props = {
  item: PropertyTypes.PropertyDetailed
  showCommission?: boolean
  isMobile?: boolean
}

const props = defineProps<Props>()

const activeSlide = ref(0)

const onSlideChange = (swiper: any) => {
  activeSlide.value = swiper.activeIndex
}
// const currency = ref('AED')

// const currencyOptions = [
//   {
//     label: 'AED',
//     value: 'AED',
//   },
//   {
//     label: 'USD',
//     value: 'USD',
//   },
// ]

const progressPercentage = computed(() => {
  if (props.item.construction_progress.length) {
    return Number(props.item.construction_progress).toFixed(0)
  }
  return 0
})

const isNotFinished = computed(() => {
  return isFuture(new Date(props.item.planned_completion_at))
})

const paymentPlans = computed(() => useGetPropertyPaymentPlans(props.item.payment_plans))
</script>

<style scoped lang="scss">
:deep() {
  .swiper {
    &-button-prev,
    &-button-next {
      @apply -top-4 mt-0 size-5 -translate-y-full;
      @media screen and (max-width: 1024px) {
        @apply top-5 size-4;
      }
      &:after {
        @apply text-sm text-black-100;
      }
      &.swiper-button-disabled {
        opacity: 1;
        &:after {
          @apply text-black-40;
        }
      }
    }
    &-button-prev {
      @apply right-8;
      left: initial !important;
      @media screen and (max-width: 1024px) {
        left: 0px !important;
      }
    }
    &-button-next {
      @apply right-0;
    }
  }
}
</style>
